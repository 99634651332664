
















 
import {
    Component,
    Vue,
} from 'vue-property-decorator';
import {
    User
} from "@/store/user";
import {
    Auth
} from "@/store/auth";
import Loading from "@/components/Web/Loading.vue";
@Component({
    components: {
  Loading
    },
    computed: {}
}) export default class Test extends Vue {
    page: any = ''
    response: boolean = false
    
    async created() {
       
    }
}
